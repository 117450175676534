import { Dispatch } from '@reduxjs/toolkit';
import { appAPI, getHeaderConfig } from '../apiConn';
import { Organisation, OrganisationAnalytics } from '../types/account';
import { APIError } from '../types/api';

/**
 * Get the user's organisation
 * @param onSuccess
 * @param onError
 */
export const GetOrganisation =
  (
    onSuccess: (organisation: Organisation) => void,
    onError: (err: APIError) => void
  ) =>
  (dispatch: Dispatch) => {
    appAPI
      .get('/organisations', getHeaderConfig())
      .then((res) => {
        // Get the Response
        let response: Organisation = res.data;

        // Call the Success Method
        onSuccess(response);
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };

/**
 * Get the user's organisation analytics
 *
 * @param onSuccess
 * @param onError
 *
 */
export const GetOrganisationAnalytics =
  (
    onSuccess: (analytics: OrganisationAnalytics) => void,
    onError: (err: APIError) => void
  ) =>
  (dispatch: Dispatch) => {
    appAPI
      .get('/organisations/analytics', getHeaderConfig())
      .then((res) => {
        // Get the Response
        let response: OrganisationAnalytics = res.data;

        // Call the Success Method
        onSuccess(response);
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };
