import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Login } from '../Application/actions/auth';
import { useAppDispatch } from '../Application/hooks';
import { APIError } from '../Application/types/api';
import { LoginRequest } from '../Application/types/auth';
import ErrorAlert from '../Components/Layout/ErrorAlert';
import logoImage from '../login-logo.png';

const LoginPage: React.FC = () => {
  // Set the Application Dispatcher
  const dispatch = useAppDispatch();

  // Set the Navigator
  const navigate = useNavigate();

  // Login Functionality
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<APIError | undefined>(undefined);
  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    // Prevent Default Behaviour
    e.preventDefault();

    // Create the Login Request
    const loginRequest: LoginRequest = {
      email: email,
      password: password,
    };

    // Attempt to Login
    dispatch(
      Login(
        loginRequest,
        () => {
          navigate('/', { replace: true });
        },
        (err) => {
          setError(err);
        }
      )
    );
  };

  // Render
  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logoImage} alt='AnalytiQR' width='100%' />
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box component='form' sx={{ mt: 1 }} onSubmit={onLogin}>
          <ErrorAlert error={error?.message} />
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email Address'
            name='email'
            autoComplete='Off'
            autoFocus
            value={email}
            onChange={onEmailChange}
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Password'
            type='password'
            id='password'
            autoComplete='Off'
            value={password}
            onChange={onPasswordChange}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component={RouterLink}
                to='/forgot-password'
                variant='body2'
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href='https://www.analytiqr.com' variant='body2'>
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginPage;
