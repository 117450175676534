import IconProps from './IconProps';

const AnalytiQRHeaderLogo: React.FC<IconProps> = (props: IconProps) => {
  // Render
  return (
    <svg
      id={props.id}
      height={props.size}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 5099.62 1222.29'
    >
      <path d='m62.06,911.9c-17.5,0-31.6-4-42.2-12s-16.9-18.8-19.1-32.3c-2.2-13.5.4-28.7,7.7-45.5L297.76,181.1c9.5-21.2,21-36.3,34.5-45.5,13.5-9.1,29-13.7,46.6-13.7,16.2-.2,32.1,4.6,45.5,13.7,13.5,9.1,25,24.3,34.5,45.5l290.4,641c8,16.8,10.9,32.1,8.8,46s-8.4,24.6-18.6,32.3c-10.2,7.7-23.8,11.5-40.5,11.5-20.5,0-36.4-4.9-47.7-14.8-11.3-9.9-21.4-25-30.1-45.5l-71.3-165.5,59.2,38.4H146.46l59.2-38.4-70.1,165.5c-9.5,21.2-19.4,36.5-29.6,46-10.3,9.5-24.9,14.3-43.9,14.3ZM376.56,283l-153.4,364.8-28.5-35.1h366l-27.4,35.1-154.5-364.8h-2.2Z' />
      <path d='m924.46,911.9c-21.9,0-38.7-6-50.4-18.1s-17.5-29.4-17.5-52v-414.2c0-22.6,5.8-39.8,17.5-51.5s28.1-17.5,49.3-17.5,37.6,5.8,49.3,17.5,17.5,28.9,17.5,51.5v74.5l-12-27.4c16.1-38.7,41.1-68.1,75.1-88.2s72.5-30.1,115.6-30.1,78.5,8,106.3,24.1,48.6,40.4,62.5,72.9,20.8,73.6,20.8,123.3v265.2c0,22.7-5.8,40-17.5,52s-28.5,18-50.4,18.1c-21.9,0-38.9-6-51-18.1s-18.1-29.4-18.1-52v-258.7c0-41.6-7.9-72-23.6-90.9s-40-28.5-72.8-28.5c-40.2,0-72.1,12.6-95.9,37.8-23.7,25.2-35.6,58.6-35.6,100.3v240c-.1,46.7-23.1,70-69.1,70Z' />
      <path d='m1673.96,914.1c-38.7,0-73.2-7.5-103.6-22.5-30.3-15-54-35.2-71.2-60.8s-25.8-54.4-25.8-86.6c0-39.4,10.2-70.7,30.7-93.7s53.7-39.6,99.7-49.8,107.7-15.3,185.2-15.3h54.8v78.9h-53.7c-45.3,0-81.5,2.2-108.5,6.6-27,4.4-46.2,11.9-57.5,22.5s-17,25.4-17,44.4c0,24.1,8.4,43.8,25.2,59.2,16.8,15.3,40.2,23,70.1,23,24.1,0,45.5-5.7,64.1-17,18.7-11.3,34-27.4,44.4-46.6,11-19.7,16.4-42.4,16.4-67.9v-126c0-36.5-8-62.8-24.1-78.9s-43.1-24.1-81.1-24.1c-21.2,0-44,2.6-68.5,7.7s-50.2,13.9-77.3,26.3c-13.9,6.6-26.1,8.4-36.7,5.5-10.2-2.6-19.1-9.1-24.7-18.1-5.8-9-8.9-19.4-8.8-30.1,0-11,2.9-21.7,8.8-32.3s15.7-18.4,29.6-23.6c33.6-13.9,65.9-23.7,97-29.6s59.4-8.8,84.9-8.8c52.6,0,95.9,8,129.8,24.1s59.4,40.4,76.2,72.9c16.8,32.5,25.2,74.3,25.2,125.5v263c0,22.7-5.5,40-16.4,52s-26.7,18-47.1,18.1c-20.5,0-36.3-6-47.7-18.1s-17-29.4-17-52v-52.6h8.8c-5.1,25.6-15.2,47.7-30.1,66.3-15.1,18.7-34.4,33.6-56.4,43.3-22.6,10-48.5,15.1-77.7,15.1Z' />
      <path d='m2279.86,914.1c-60.7,0-106.3-17.3-137-52s-46-85.6-46-152.9V190.9c0-22.7,5.8-39.8,17.5-51.5s28.5-17.5,50.4-17.5,38.9,5.8,51,17.5,18.1,28.9,18.1,51.5v511.7c0,33.6,7.1,58.5,21.4,74.5,14.2,16.1,34.5,24.1,60.8,24.1,5.8,0,11.3-.2,16.4-.5s10.2-.9,15.3-1.6c10.2-1.5,17.2,1.3,20.8,8.2,3.7,6.9,5.5,21,5.5,42.2,0,18.3-3.7,32.5-11,42.7s-19.4,16.4-36.2,18.6c-7.3.7-15,1.5-23,2.2s-15.9,1.1-24,1.1Z' />
      <path d='m2568.06,1109.2c-16.1,0-29.2-4.2-39.4-12.6s-16.3-19.5-18.1-33.4.5-28.5,7.1-43.8l74.5-165.5v57l-199.4-461.3c-6.6-16.1-8.8-31-6.6-44.9,1.9-13.4,9.2-25.5,20.3-33.4,11.3-8.4,26.9-12.6,46.6-12.6,16.8,0,30.3,3.8,40.5,11.5s19.4,22.1,27.4,43.3l147.9,369.3h-32.9l151.2-370.4c8-20.4,17.5-34.5,28.5-42.2s25.6-11.5,43.8-11.5c16.1,0,28.8,4.2,38.4,12.6,9.5,8.4,15.3,19.3,17.5,32.8s-.4,28.3-7.7,44.4l-266.3,608.2c-9.5,20.4-19.7,34.3-30.7,41.6-10.8,7.2-25,10.9-42.6,10.9Z' />
      <path d='m3270.46,914.1c-47.5,0-87.1-8-118.9-24.1s-55.3-39.6-70.7-70.7c-15.3-31-23-69.6-23-115.6v-233.4h-57c-17.5,0-31.1-4.6-40.6-13.7s-14.2-21.7-14.2-37.8,4.7-29.6,14.2-38.4,23-13.1,40.6-13.1h57v-99.7c0-22.6,6-39.8,18.1-51.5s29.1-17.6,51-17.5c21.9,0,38.7,5.8,50.4,17.5s17.5,28.9,17.5,51.5v99.7h116.1c17.5,0,31,4.4,40.5,13.1s14.2,21.6,14.2,38.4-4.8,28.7-14.2,37.8-23,13.7-40.5,13.7h-116.1v225.7c0,35.1,7.7,61.4,23,78.9s40.2,26.3,74.5,26.3c11,.1,22.1-1,32.9-3.3,8.3-2,16.7-3.4,25.2-4.4,8.8-.7,16.1,2,21.9,8.2,5.8,6.2,8.8,18.8,8.8,37.8,0,14.6-2.4,27.6-7.1,38.9-4.8,11.3-13.3,19.2-25.8,23.6-9.5,2.9-21.9,5.6-37.2,8.2-13.5,2.5-27,3.7-40.6,3.9Z' />
      <path d='m3527.96,257.8c-25.6,0-45.5-6.4-59.7-19.2s-21.4-30.5-21.4-53.2,7.1-41.3,21.4-53.7c14.2-12.4,34.1-18.6,59.7-18.6s46.4,6.2,60.3,18.6c13.9,12.4,20.8,30.3,20.8,53.7s-6.9,40.4-20.8,53.1c-13.9,12.9-34,19.2-60.3,19.3Zm0,653c-21.9,0-38.7-6.6-50.4-19.7s-17.6-31.4-17.6-54.8v-402.1c0-24.1,5.8-42.6,17.5-55.3,11.7-12.8,28.5-19.2,50.4-19.2s38.9,6.4,51,19.2c12.1,12.8,18.1,31.2,18.1,55.3v402.2c0,23.4-5.8,41.6-17.5,54.8-11.7,13-28.9,19.6-51.5,19.6Z' />
      <path d='m4441.16,1219.4c0,1.6-1.3,2.8-2.9,2.8h-545.2c-1.6,0-2.8-1.3-2.9-2.8v-545.3c0-1.6,1.3-2.8,2.9-2.8h545.2c1.6,0,2.8,1.3,2.9,2.8v545.3Zm-103.4-441.9c0-1.6-1.3-2.8-2.9-2.8h-338.7c-1.6,0-2.8,1.3-2.9,2.8v338.3c0,1.6,1.3,2.8,2.9,2.8h338.8c1.6,0,2.8-1.3,2.9-2.8l-.1-338.3Z' />
      <path d='m5099.36,905.1c0,1.5-1.2,2.8-2.8,2.8h-230.9c-1.5,0-2.8-1.2-2.8-2.7h0v-231.2c0-1.5,1.2-2.8,2.7-2.8h231c1.5,0,2.8,1.2,2.8,2.7v231.2h0Zm-44.1-187.3c0-1.1-.9-2-2-2l-143.5-.2c-1.1,0-2,.9-2,2l-.2,143.7c0,1.1.9,2,2,2l143.5.2c1.1,0,2-.9,2-2l.2-143.7Z' />
      <path d='m4550.96,671.4h231.4c1.4,0,2.5,1.1,2.5,2.5l.1,231.4c0,1.4-1.1,2.5-2.5,2.5l-231.4.1c-1.4,0-2.5-1.1-2.5-2.5l-.1-231.5c0-1.4,1.1-2.5,2.5-2.5m190,46.3c0-1.2-1-2.2-2.2-2.2h-143.4c-1.2,0-2.2,1-2.2,2.2h0v143.3c0,1.2,1,2.2,2.2,2.2h143.4c1.2,0,2.2-1,2.2-2.2h0v-143.3Z' />
      <path d='m4715.46,835.1c0,1.8-1.4,3.2-3.2,3.2l-90.8.2c-1.8,0-3.2-1.4-3.2-3.2l-.2-91.1c0-1.8,1.4-3.2,3.2-3.2l90.8-.2c1.8,0,3.2,1.4,3.2,3.2l.2,91.1Z' />
      <path d='m5029.96,835.2c0,1.6-1.3,2.9-2.9,2.9h0l-91.3.2c-1.6,0-2.9-1.3-2.9-2.9h0l-.2-91.3c0-1.6,1.3-2.9,2.9-2.9h0l91.3-.2c1.6,0,2.9,1.3,2.9,2.9h0l.2,91.3Z' />
      <path d='m4055.66,833.7h220.3c1.6,0,2.9,1.3,2.9,2.9h0v220.6c0,1.6-1.3,2.9-2.9,2.9h-220.3c-1.6,0-2.9-1.3-2.9-2.9v-220.6c0-1.6,1.3-2.9,2.9-2.9Z' />
      <path d='m4785.06,1219.5c0,1.5-1.2,2.8-2.7,2.8h-230.9c-1.5,0-2.8-1.2-2.8-2.7v-230.9c0-1.5,1.2-2.8,2.7-2.8h231c1.5,0,2.8,1.2,2.8,2.7h0v230.9h-.1Zm-44.3-187.1c0-1.4-1.2-2.6-2.6-2.6l-142.7.2c-1.4,0-2.6,1.2-2.6,2.6l.2,142.7c0,1.4,1.2,2.6,2.6,2.6l142.7-.2c1.4,0,2.6-1.2,2.6-2.6l-.2-142.7Z' />
      <path d='m5099.56,1219.7c0,1.4-1.2,2.6-2.6,2.6h-231.2c-1.4,0-2.6-1.2-2.6-2.6v-231.2c0-1.4,1.2-2.6,2.6-2.6h231.2c1.4,0,2.6,1.2,2.6,2.6v231.2Zm-44.3-187.5c0-1.2-1-2.1-2.1-2.1l-143.3-.2c-1.2,0-2.1,1-2.1,2.1l-.2,143.8c0,1.2,1,2.1,2.1,2.1l143.3.2c1.2,0,2.1-1,2.1-2.1l.2-143.8Z' />
      <path d='m4715.36,1149.9c0,1.4-1.2,2.6-2.6,2.6l-92,.2c-1.4,0-2.6-1.2-2.6-2.6l-.2-92c0-1.4,1.2-2.6,2.6-2.6l92-.2c1.4,0,2.6,1.2,2.6,2.6l.2,92Z' />
      <path d='m5030.06,1149.6c0,1.6-1.3,2.9-2.9,2.9h0l-91.7.2c-1.6,0-2.9-1.3-2.9-2.9h0l-.1-91.4c0-1.6,1.3-2.9,2.9-2.9h0l91.7-.2c1.6,0,2.9,1.3,2.9,2.9h0l.1,91.4Z' />
      <path d='m4165.96,155.4c-14.7,0-27.4,3.4-38,10.2s-18.7,16.7-24.3,29.7c-5.7,13-8.5,28.7-8.5,47.1,0,27.4,6.2,48.8,18.7,64.2,12.3,15.4,29.7,23.2,52,23.2,15,0,27.7-3.5,38.2-10.4,10.5-6.9,18.5-16.9,24.2-30,5.6-13.1,8.5-28.8,8.5-47,0-27.6-6.2-49-18.5-64.2s-29.8-22.8-52.3-22.8Z' />
      <path d='m4438.66,0h-545.8c-1.3,0-2.4,1.1-2.4,2.5v546c0,1.4,1.1,2.5,2.5,2.5h545.8c1.4,0,2.5-1.1,2.5-2.5V2.5c-.1-1.4-1.2-2.5-2.6-2.5Zm-163.8,294c-5.1,14.7-13.2,28.2-23.7,39.7-9.11,9.82-20.03,17.76-32.12,23.34,2.42.81,4.64,1.78,6.63,2.96,6.5,3.8,12.7,10.4,18.6,19.9l14.8,23.5c3.2,5.1,4.5,9.7,3.8,13.8-.6,4-2.6,7.7-5.5,10.5-3.1,2.9-6.9,4.8-11,5.5-4.4.8-9,.3-13.1-1.4-4.4-1.7-8.1-5-11-9.8l-23.8-38.3c-3.1-5.1-7.6-9.3-13-11.9-5.4-2.7-12-4-19.9-4h.01c-23.38-.05-43.78-5.24-61.31-15.5-17.6-10.3-31.8-25.5-40.9-43.7-9.7-18.8-14.5-40.8-14.5-66.1,0-19.1,2.8-36.3,8.3-51.6,5.1-14.6,13.2-28,23.7-39.4,10.3-11,23-19.6,37-25.2,14.4-5.9,30.4-8.8,48.2-8.8,23.7,0,44.3,5.1,61.8,15.4,17.5,10.3,31.1,24.7,40.8,43.3,9.7,18.7,14.5,40.7,14.5,66,0,19.1-2.8,36.4-8.3,51.8Z' />
      <path d='m4886.66,208.9c0-16.2-5.1-28.4-15.4-36.7s-25.4-12.4-45.3-12.3h-67.7l.1,98.5h67.6c19.9,0,35.1-4.1,45.3-12.4,10.3-8.2,15.4-20.6,15.4-37.1Z' />
      <path d='m5095.86.1h-543.6c-2,0-3.7,1.7-3.7,3.7v543.5c0,2,1.7,3.7,3.7,3.7h543.6c2,0,3.7-1.7,3.7-3.7V3.8c0-2-1.7-3.6-3.7-3.7Zm-150.8,405.8c-.1,5.4-2.5,10.5-6.5,14.1-4.1,3.6-10,5.4-17.8,5.4s-14.2-1.7-19.1-5-9.3-8.5-13-15.4l-43.8-80.7c-5.2-9.8-11.4-16.4-18.4-19.7-7-3.3-16.1-5-27.1-5h-41.2v97.2c0,9-2.3,16-6.9,21-4.5,5-11.5,7.6-20.7,7.6s-15.8-2.5-20.6-7.6c-4.8-5.1-7.2-12.1-7.2-21v-252.4c0-9.3,2.5-16.3,7.4-21,4.9-4.7,11.9-7.1,20.8-7.2h104.5c33.5,0,59.4,8,77.6,24.1,18.2,16.1,27.3,38.5,27.3,67.4,0,18.8-4.1,35-12.4,48.6-8.2,13.6-20.2,24-35.8,31.2-6.27,2.89-13.09,5.2-20.43,6.93,4.59,1.44,8.86,3.46,12.83,6.07,8.8,5.8,16.6,14.9,23.2,27.3l32.5,60.3c3.5,6.1,5.1,12,4.8,17.8Z' />
    </svg>
  );
};

export default AnalytiQRHeaderLogo;
