import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { GetOrganisation } from '../Application/actions/organisation';
import { deleteCookie } from '../Application/cookie';
import { useAppDispatch } from '../Application/hooks';
import { Organisation } from '../Application/types/account';
import AnalytiQRHeaderLogo from './Icons/AnalytiQRHeaderLogo';
import LeftDrawer from './Navigation/LeftDrawer';

const drawerWidth = 240;

const Dashboard: React.FC = () => {
  // Set the App Dispatch
  const dispatch = useAppDispatch();

  // Set the Navigator
  const navigate = useNavigate();

  // Organisation Accessor
  const [organisation, setOrganisation] = useState<Organisation | undefined>(
    undefined
  );

  // Trial Redirect Accessor
  const [trialRedirect, setTrialRedirect] = useState<boolean>(false);

  useEffect(() => {
    if (!organisation) {
      dispatch(
        GetOrganisation(
          (org) => {
            if (
              org.trial_ends_on &&
              org.trial_ends_on !== '0001-01-01T00:00:00Z' &&
              org.trial_ends_on !== '1970-01-01T00:00:00Z'
            ) {
              const trialEndsOn = new Date(org.trial_ends_on);
              const today = new Date();
              if (
                trialEndsOn < today &&
                window.location.pathname !== '/billing/upgrade'
              ) {
                setTrialRedirect(true);
              }
            }
            setOrganisation(org);
          },
          () => {
            // Log Out
            deleteCookie('token');
            navigate('/login');
          }
        )
      );
    }
  }, [organisation, dispatch, navigate]);

  // Render
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {trialRedirect && <Navigate to='/billing/upgrade' replace={true} />}
      <AppBar
        position='fixed'
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <AnalytiQRHeaderLogo id='logo' size='50px' />
        </Toolbar>
      </AppBar>
      <LeftDrawer width={drawerWidth} />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
