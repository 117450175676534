import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

type NavItemProps = {
  text: string;
  href: string;
  icon?: React.ReactNode;
};

const NavItem: React.FC<NavItemProps> = (props: NavItemProps) => {
  // Location Hook
  const location = useLocation();

  // Set the Active Class
  const [active, setActive] = useState<boolean>(false);

  // useEffect
  useEffect(() => {
    if (props.href) {
      if (location.pathname === props.href) {
        setActive(true);
      } else if (location.pathname.includes(props.href) && props.href !== '/') {
        setActive(true);
      } else {
        setActive(false);
      }
    }
  }, [props.href, location]);

  // Render
  return (
    <ListItem>
      <ListItemButton component={Link} to={props.href} selected={active}>
        <ListItemIcon>{props.icon}</ListItemIcon>
        <ListItemText primary={props.text} />
      </ListItemButton>
    </ListItem>
  );
};

export default NavItem;
