import '@fontsource/nunito/700.css';
import '@fontsource/ubuntu/400.css';
import '@fontsource/ubuntu/700.css';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import Loading from './Components/Loading';
import RequireAuth from './Components/RequireAuth';
import LoginPage from './Pages/Login';
import aqrTheme from './theme';

function App() {
  const ForgotPasswordPage = React.lazy(() => import('./Pages/ForgotPassword'));
  const ResetPasswordPage = React.lazy(() => import('./Pages/ResetPassword'));
  const VerifyEmailPage = React.lazy(() => import('./Pages/VerifyEmail'));
  const ResendVerifyEmailPage = React.lazy(
    () => import('./Pages/ResendVerifyEmail')
  );
  const OverviewPage = React.lazy(() => import('./Pages/Overview'));
  const CampaignListPage = React.lazy(() => import('./Pages/CampaignList'));
  const CampaignOverviewPage = React.lazy(
    () => import('./Pages/CampaignOverview')
  );
  const NewCampaignPage = React.lazy(() => import('./Pages/CampaignNew'));
  const QRCodeOverviewPage = React.lazy(() => import('./Pages/QRCodeOverview'));
  const NewQRCodePage = React.lazy(() => import('./Pages/QRCodeNew'));
  const EditQRCodePage = React.lazy(() => import('./Pages/QRCodeEdit'));
  const BillingPage = React.lazy(() => import('./Pages/Billing'));
  const BillingUpgradePage = React.lazy(() => import('./Pages/BillingUpgrade'));
  const UpcomingFeaturesPage = React.lazy(
    () => import('./Pages/UpcomingFeatures')
  );

  return (
    <ThemeProvider theme={aqrTheme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <Router>
          <Routes>
            <Route path='/login' element={<LoginPage />} />
            <Route
              path='/forgot-password'
              element={
                <Suspense fallback={<Loading />}>
                  <ForgotPasswordPage />
                </Suspense>
              }
            />
            <Route
              path='/reset-password/:code'
              element={
                <Suspense fallback={<Loading />}>
                  <ResetPasswordPage />
                </Suspense>
              }
            />
            <Route
              path='/verify-email/:code'
              element={
                <Suspense fallback={<Loading />}>
                  <VerifyEmailPage />
                </Suspense>
              }
            />
            <Route
              path='/verify-email/resend'
              element={
                <Suspense fallback={<Loading />}>
                  <ResendVerifyEmailPage />
                </Suspense>
              }
            />
            <Route
              path='/'
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<Loading />}>
                    <OverviewPage />
                  </Suspense>
                }
              />
              <Route
                path='/campaigns'
                element={
                  <Suspense fallback={<Loading />}>
                    <CampaignListPage />
                  </Suspense>
                }
              />
              <Route
                path='/campaigns/new'
                element={
                  <Suspense fallback={<Loading />}>
                    <NewCampaignPage />
                  </Suspense>
                }
              />
              <Route
                path='/campaigns/:id'
                element={
                  <Suspense fallback={<Loading />}>
                    <CampaignOverviewPage />
                  </Suspense>
                }
              />
              <Route
                path='/campaigns/:id/qr-codes/new'
                element={
                  <Suspense fallback={<Loading />}>
                    <NewQRCodePage />
                  </Suspense>
                }
              />
              <Route
                path='/campaigns/:id/qr-codes/:qrID'
                element={
                  <Suspense fallback={<Loading />}>
                    <QRCodeOverviewPage />
                  </Suspense>
                }
              />
              <Route
                path='/campaigns/:id/qr-codes/:qrID/edit'
                element={
                  <Suspense fallback={<Loading />}>
                    <EditQRCodePage />
                  </Suspense>
                }
              />
              <Route
                path='/billing'
                element={
                  <Suspense fallback={<Loading />}>
                    <BillingPage />
                  </Suspense>
                }
              />
              <Route
                path='/billing/upgrade'
                element={
                  <Suspense fallback={<Loading />}>
                    <BillingUpgradePage />{' '}
                  </Suspense>
                }
              />
              <Route
                path='/upcoming-features'
                element={
                  <Suspense fallback={<Loading />}>
                    <UpcomingFeaturesPage />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
