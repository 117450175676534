import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Campaign, QRCode } from '../types/campaign';

// CampaignState interface
interface CampaignState {
  campaigns?: Campaign[];
  currentCampaign?: Campaign;
  qrCodes?: QRCode[];
  currentQRCode?: QRCode;
}

// Define the Initial State
const initialState: CampaignState = {
  campaigns: undefined,
  currentCampaign: undefined,
  qrCodes: undefined,
  currentQRCode: undefined,
};

// Define the Methods for Managing CampaignState
export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaigns: (state, action: PayloadAction<Campaign[]>) => {
      state.campaigns = action.payload;
    },
    setCurrentCampaign: (state, action: PayloadAction<Campaign>) => {
      state.currentCampaign = action.payload;
    },
    setQRCodes: (state, action: PayloadAction<QRCode[]>) => {
      state.qrCodes = action.payload;
    },
    setCurrentQRCode: (state, action: PayloadAction<QRCode>) => {
      state.currentQRCode = action.payload;
    },
  },
});

// Export the Actions
export const {
  setCampaigns,
  setCurrentCampaign,
  setQRCodes,
  setCurrentQRCode,
} = campaignSlice.actions;

// Export the Selectors
export const allCampaigns = (state: RootState) => state.campaign.campaigns;
export const currentCampaign = (state: RootState) =>
  state.campaign.currentCampaign;
export const allQRCodes = (state: RootState) => state.campaign.qrCodes;
export const currentQRCode = (state: RootState) => state.campaign.currentQRCode;

// Export the Reducer
export default campaignSlice.reducer;
