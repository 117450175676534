import Alert from '@mui/material/Alert';

type ErrorAlertProps = {
  error?: string;
};

const ErrorAlert: React.FC<ErrorAlertProps> = (props: ErrorAlertProps) => {
  // Render
  return <>{props.error && <Alert severity='error'>{props.error}</Alert>}</>;
};

export default ErrorAlert;
