import { Dispatch } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { appAPI, getHeaderConfig } from '../apiConn';
import { setCookie } from '../cookie';
import { setCurrentAccount } from '../features/account';
import { Account } from '../types/account';
import { APIError } from '../types/api';
import {
  ForgotPasswordRequest,
  LoginRequest,
  LoginResponse,
  ResendVerifyEmailRequest,
  ResetPasswordRequest,
  VerifyEmailRequest,
  jwt,
} from '../types/auth';

/**
 * Log in to the system
 *
 * @param req LoginRequest containing email and password
 * @param onSuccess Method called on success
 * @param onError Method called on error, returns APIError
 *
 */
export const Login =
  (
    req: LoginRequest,
    onSuccess: () => void,
    onError: (err: APIError) => void
  ) =>
  async (dispatch: Dispatch) => {
    appAPI
      .post('/auth/login', req)
      .then((res) => {
        // Get the Response
        let response: LoginResponse = res.data;

        // Decode the Token
        const token = jwtDecode<jwt>(response.token);

        // Create the Expiry Date
        const expiry = new Date();
        expiry.setTime(token.exp * 1000);

        // Set the Cookie
        setCookie('token', response.token, expiry);

        // Set the Current Account
        dispatch(setCurrentAccount(response.account));

        // Call the Success Method
        onSuccess();
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };

/**
 * Send a Password Reset Link
 *
 * @param forgotPasswordRequest ForgotPasswordRequest containing email
 * @param onSuccess Method called on success
 * @param onError Method called on error, returns APIError
 */
export const SendResetLink =
  (
    forgotPasswordRequest: ForgotPasswordRequest,
    onSuccess: () => void,
    onError: (err: APIError) => void
  ) =>
  async (dispatch: Dispatch) => {
    appAPI
      .post('/auth/forgot-password', forgotPasswordRequest)
      .then(() => {
        // Call the Success Method
        onSuccess();
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };

/**
 * Reset the password
 *
 * @param resetPasswordRequest ResetPasswordRequest containing password and reset password code
 * @param onSuccess Method called on success
 * @param onError Method called on error, returns APIError
 */
export const ResetPassword =
  (
    resetPasswordRequest: ResetPasswordRequest,
    onSuccess: () => void,
    onError: (err: APIError) => void
  ) =>
  async (dispatch: Dispatch) => {
    appAPI
      .post('/auth/reset-password', resetPasswordRequest)
      .then(() => {
        // Call the Success Method
        onSuccess();
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };

/**
 * Verify the email address
 *
 * @param verifyEmailRequest VerifyEmailRequest containing email verification code
 * @param onSuccess Method called on success
 * @param onError Method called on error, returns APIError
 * @returns
 */
export const VerifyEmail =
  (
    verifyEmailRequest: VerifyEmailRequest,
    onSuccess: () => void,
    onError: (error: APIError) => void
  ) =>
  async (dispatch: Dispatch) => {
    appAPI
      .post('/auth/verify-email', verifyEmailRequest)
      .then((res) => {
        // Call the Success Method
        onSuccess();
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };

/**
 * Get the account from the verification code
 *
 * @param code Verification Code
 * @param onSuccess Method called on success
 * @param onError Method called on error, returns APIError
 */
export const GetVerificationAccount =
  (
    code: string,
    onSuccess: (account: Account) => void,
    onError: (error: APIError) => void
  ) =>
  async (dispatch: Dispatch) => {
    appAPI
      .get(`/auth/verify-email?code=${code}`)
      .then((res) => {
        // Get the Response
        let response: Account = res.data;

        // Call the Success Method
        onSuccess(response);
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };

/**
 *
 * @param req Email to resend verification email to
 * @param onSuccess
 * @param onError
 */
export const ResendEmailVerification = (
  req: ResendVerifyEmailRequest,
  onSuccess: () => void,
  onError: (err: APIError) => void
) => {
  appAPI
    .post('/auth/verify-email/resend', req)
    .then(() => {
      // Call the Success Method
      onSuccess();
    })
    .catch((err) => {
      // Get the Error Message
      let error: APIError = err.response.data;

      // Call the Error Method
      onError(error);
    });
};
/**
 *
 * @param onError Method called on error, returns APIError
 * @returns
 */
export const GetLoggedInAccount =
  (onSuccess: (account: Account) => void, onError: (err: APIError) => void) =>
  async (dispatch: Dispatch) => {
    await appAPI
      .get('/accounts', getHeaderConfig())
      .then((res) => {
        // Get the Response
        let response: Account = res.data;

        // Call the Success Method
        onSuccess(response);

        // Set the Current Account
        dispatch(setCurrentAccount(response));
      })
      .catch((err) => {
        // Get the Error Message
        let error: APIError = err.response.data;

        // Call the Error Method
        onError(error);
      });
  };
