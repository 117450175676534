import { createTheme } from '@mui/material/styles';

// Set the Brand Colors
export const brandColors = {
  50: '#ffe2ec',
  100: '#ffb3c5',
  200: '#fc839f',
  300: '#f95278',
  400: '#f62252',
  500: '#dd0939',
  600: '#ad032c',
  700: '#7c001e',
  800: '#4d0012',
  900: '#200005',
};

const aqrTheme = createTheme({
  palette: {
    primary: {
      light: brandColors[200],
      main: brandColors[400],
      dark: brandColors[600],
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    h2: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    h3: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    h4: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
    h6: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
    },
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.hover': {
            backgroundColor: brandColors[50],
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected span': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: brandColors[400],
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& th': {
            fontWeight: 'bold',
            fontFamily: ['Nunito', 'sans-serif'].join(','),
          },
        },
      },
    },
    // MuiLink: {
    //   styleOverrides: {
    //     root: {
    //       color: brandColors[400],
    //     },
    //   },
    // },
  },
});

export default aqrTheme;
