import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GradeIcon from '@mui/icons-material/Grade';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from '../../Application/cookie';
import NavItem from './NavItem';

type LeftDrawerProps = {
  width: number;
};

const LeftDrawer: React.FC<LeftDrawerProps> = (props: LeftDrawerProps) => {
  // Navigator
  const navigate = useNavigate();

  // Logout Action
  const onLogout = (e: React.MouseEvent<HTMLDivElement>) => {
    deleteCookie('token');
    navigate('/login');
  };

  // Render
  return (
    <Drawer
      sx={{
        width: props.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: props.width,
          boxSizing: 'border-box',
        },
      }}
      variant='permanent'
      anchor='left'
    >
      <Toolbar />
      <Divider />
      <List>
        <NavItem text='Home' href='/' icon={<HomeIcon />} />
        <NavItem text='Campaigns' href='/campaigns' icon={<BarChartIcon />} />
        <NavItem text='Billing' href='/billing' icon={<CreditCardIcon />} />
        <NavItem
          text='Upcoming'
          href='/upcoming-features'
          icon={<GradeIcon />}
        />
        <ListItem>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Log Out' />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default LeftDrawer;
