import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Account } from '../types/account';

// AccountState interface
interface AccountState {
  loggedInAccount?: Account;
}

// Define the Initial State
const initialState: AccountState = {
  loggedInAccount: undefined,
};

// Define the Methods for Managing AccountState
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setCurrentAccount: (state, action: PayloadAction<Account>) => {
      state.loggedInAccount = action.payload;
    },
  },
});

// Export the Actions
export const { setCurrentAccount } = accountSlice.actions;

// Export the Selectors
export const currentAccount = (state: RootState) =>
  state.account.loggedInAccount;

// Export the Reducer
export default accountSlice.reducer;
